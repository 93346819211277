const environment = {
	production: false,
	client_id: 'amzn1.application-oa2-client.31f4ff29ddf845d4a1c3eab971504af2',
	public_key: 'PsRwYu6JVWa4Ms4RkHOecDao0erme6QaaQCk52u0',
	devURL: 'https://dev.bontu-dev.io/',
	chat: {
		host: 'https://wchat.freshchat.com',
		token: '83ff6496-0c31-41db-9072-3f539aa3cca0'
	},
	cognitoConfig: {
	  Auth: {
		identityPoolId: 'us-east-1:598f09e2-14bd-4dce-8eb4-3b4846af425f',
		region: 'us-east-1',
		identityPoolRegion: 'us-east-1',
		userPoolId: 'us-east-1_FhVXETw9E',
		userPoolWebClientId: '6n5a2hi5ofbbo9eoetg75vkdd1',
		oauth: {
		  domain: 'qa-userappauth.auth.us-east-1.amazoncognito.com',
		  redirectSignIn: 'https://www.qa-userapp.bontu-dev.io/callback',
		  redirectSignOut: 'https://www.qa-userapp.bontu-dev.io/login',
		  responseType: 'token'
		}
	  },
	  API: {
		endpoints: [
		  {
			name: 'ApiBontu',
			endpoint: 'https://api.bontu-dev.io/qa'
		  }
		]
	  }
	},
};

export default environment;